import React from "react";
import "./tenantMngDashboard.css";
//
import { Link } from "react-router-dom";

const TenantMngDashboard = () => {
  return (
    <div>
      <div className="MenuCards">
        <div className="card">
          <Link to="new_tenant">New Self Hosted DB Tenant</Link>
        </div>
        <div className="card">New Trial Tenant</div>
        <div className="card">
          <Link to="new_shared_tenant">New Shared DB Tenant</Link>
        </div>
        <div className="card">New Dedicated DB Tenant</div>
      </div>
    </div>
  );
};

export default TenantMngDashboard;
