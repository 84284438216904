import axios from "axios";
//apis
const addRemoteDBTenants = async (props: any) => {
  const {
    TenantName,
    TenantEmail,
    // TenantCPName,
    //TenantCPEmail,
    TenantDBHost,
    TenantDBName,
    TenantDBPassword,
    TenantDBUser,
    SubscriptionModules,
  } = props;
  //
  try {
    if (!TenantDBHost || !TenantDBName || !TenantDBPassword || !TenantDBUser) {
      const err = new Error("FE:incomplete database configeration credentials");
      throw err;
    }
    if (SubscriptionModules.length <= 0) {
      const err = new Error("No Subscription Module Were selected");
      throw err;
    }
    const request = await axios.post(
      `${process.env.REACT_APP_ADD_DEDECATED_TENANT_API}`,
      {
        // tenantID: props.tenantID,
        TenantName: TenantName,
        TenantEmail: TenantEmail,
        Tenant_DB_Name: TenantDBName,
        Tenant_DB_User_Name: TenantDBUser,
        Tenant_DB_Password: TenantDBPassword,
        Tenant_DB_Host: TenantDBHost,
        Tenant_Sub_Modules: SubscriptionModules,
      }
    );
    console.log(request);
    //console.log(request.data);
    // await setSessionStorage(request.data);

    return request;
  } catch (err) {
    console.log(err);
    throw err;
  }
  //
};

const addsharedTenants = async (props: any) => {
  const {
    TenantName,
    TenantEmail,
    // TenantCPName,
    //TenantCPEmail,
    ContactPersonName,
    ContactPersonEmail,
    subscriptionModules,
    // TenantDBUser,
  } = props;
  //
  try {
    const request = await axios.post(
      `${process.env.REACT_APP_ADD_SHARED_TENANT_API}`,
      {
        // tenantID: props.tenantID,
        Name: TenantName,
        Email: TenantEmail,
        REPName: ContactPersonName,
        REPEmail: ContactPersonEmail,
        SubscriptionModules: subscriptionModules,
      }
    );
    console.log(request);
    //console.log(request.data);
    // await setSessionStorage(request.data);

    return request;
  } catch (err) {
    console.log(err);
    throw err;
  }
  //
};

export { addRemoteDBTenants, addsharedTenants };
