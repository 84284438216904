import {
  createContext,
  useContext,
  useState,
  ReactNode,
  //useEffect,
} from "react";

interface AuthContextType {
  authed: boolean;
  login: () => Promise<void>;
  logout: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export function AuthProvider({ children }: { children: ReactNode }) {
  const [authed, setAuthed] = useState<boolean>(() => {
    // Check if the user is authenticated from localStorage
    return localStorage.getItem("authed") === "true";
  });

  const login = async () => {
    localStorage.setItem("authed", "true");
    setAuthed(true);
  };

  const logout = async () => {
    localStorage.removeItem("authed");
    setAuthed(false);
  };

  const value = { authed, login, logout };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuthContext() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuthContext must be used within an AuthProvider");
  }
  return context;
}
