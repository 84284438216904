import React from "react";
import "./landing.css";
import { Routes, Route, Link } from "react-router-dom";
//pages
import AdminDashboard from "../dashboard/dashboard";
import TenantMng from "../tenantMng/tenantMng";
//

const Landing = () => {
  return (
    <div className="AdminLanding">
      <div className="SideNavBar">
        <h1>HabSync</h1>
        <h2>Admin Portal</h2>
        <nav>
          <ul>
            <li>
              <Link to="./">Dashboard</Link>
            </li>
            <li>
              <Link to="./tenant_mng">Tenant Management</Link>
            </li>
            <li>
              <Link to="./">System Management</Link>
            </li>
            <li>
              <Link to="./">CRM</Link>
            </li>
            <li>
              <Link to="./">Reporting & Analytics</Link>
            </li>
            <li>
              <Link to="./">Compliance</Link>
            </li>
            <li>
              <Link to="./">Security</Link>
            </li>
            <li>
              <Link to="./">Support</Link>
            </li>
            <li>
              <Link to="./">Settings</Link>
            </li>
            <li>
              <Link to="./">Developer Tools</Link>
            </li>
            <li>
              <Link to="/">Logout</Link>
            </li>
          </ul>
        </nav>
        <footer></footer>
      </div>
      <div className="NavDisplay">
        <div className="TopNavBr">
          <h3>TopNavBar</h3>
        </div>
        <div className="NavCenterDisplay">
          <Routes>
            <Route path="/" element={<AdminDashboard />} />
            <Route path="/tenant_mng/*" element={<TenantMng />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default Landing;
