import React from "react";
import "./App.css";
import { Routes, Route } from "react-router-dom";
//auth
import { useAuthContext } from "./hooks/authcontext";

//pages
import Login from "./pages/login";
import Landing from "./pages/landing/landing";
//

function App() {
  const { authed } = useAuthContext();
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/landing/*" element={authed ? <Landing /> : <Login />} />
        <Route path="*" element={<div>Not Found!</div>} />
      </Routes>
    </div>
  );
}

export default App;
